<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-3">
        <div class="col my-auto">
          <h5 class="mb-0 client-tab-heading">Allergies & Intolerances</h5>
        </div>
        <div class="col-5 text-end">
          <div class="d-flex">
            <button
              class="btn btn-outline-primary btn-sm ms-auto"
              v-if="!showAdd"
              @click="showAdd = true"
            >
              <i class="far fa-plus me-1"></i>
              Add Allergy or Intolerance
            </button>
          </div>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <busy :visible="loading" />

      <div v-if="!loading">
        <div v-if="showAdd">
          <form @submit.prevent="createAllergy">
            <div class="card bg-light">
              <div class="card-body">
                <label>Type</label>
                <select class="form-control mb-3" v-model="form.type" required>
                  <option value="allergy">Allergy</option>
                  <option value="intolerance">Intolerance</option>
                </select>

                <label>Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  v-model="form.name"
                  :placeholder="'Enter a name for the ' + form.type + '...'"
                  required
                />

                <label>Description</label>
                <textarea
                  class="form-control mb-3"
                  rows="4"
                  placeholder="Enter a description (optional)..."
                  v-model="form.description"
                ></textarea>

                <button class="btn btn-primary float-end" type="submit">
                  <i class="far fa-plus me-1"></i>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>

        <div v-else>
          <div v-if="allergies.length === 0">
            <div class="card bg-light">
              <div class="card-body text-center">
                <i class="far fa-info-circle text-primary fa-3x"></i>
                <p>
                  No allergies or intolerances have been recorded against this
                  client.
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card bg-light">
              <div class="card-body">
                <div class="card">
                  <div class="card-body my-0 p-0">
                    <form @submit.prevent="updateAllergy">
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th scope="col" style="border-top: 0px">Type</th>
                            <th scope="col" style="border-top: 0px">Name</th>
                            <th scope="col" style="border-top: 0px">
                              Description
                            </th>
                            <th scope="col" style="border-top: 0px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="allergy in allergies">
                            <td class="text-capitalize text-nowrap">
                              <span v-if="editAllergy === allergy">
                                <select
                                  class="form-control mb-3"
                                  v-model="allergy.type"
                                  required
                                >
                                  <option value="allergy">Allergy</option>
                                  <option value="intolerance">
                                    Intolerance
                                  </option>
                                </select>
                              </span>
                              <span v-else>{{ allergy.type }}</span>
                            </td>
                            <td class="text-nowrap">
                              <span v-if="editAllergy === allergy">
                                <input
                                  type="text"
                                  class="form-control mb-3"
                                  v-model="allergy.name"
                                  :placeholder="
                                    'Enter a name for the ' +
                                    allergy.type +
                                    '...'
                                  "
                                  required
                                />
                              </span>
                              <span v-else>{{ allergy.name }}</span>
                            </td>
                            <td class="text-wrap">
                              <span v-if="editAllergy === allergy">
                                <textarea
                                  class="form-control mb-3"
                                  rows="4"
                                  placeholder="Enter a description (optional)..."
                                  v-model="allergy.description"
                                ></textarea>
                              </span>
                              <span v-else>{{ allergy.description }}</span>
                            </td>
                            <td class="text-nowrap text-end">
                              <span v-if="editAllergy === allergy">
                                <i
                                  class="
                                    fad
                                    fa-times
                                    cursor-pointer
                                    me-2
                                    border-0
                                  "
                                  @click="cancelEdit"
                                ></i>
                                <button
                                  class="
                                    fad
                                    fa-save
                                    text-primary
                                    cursor-pointer
                                    me-2
                                    border-0
                                  "
                                  type="submit"
                                ></button>
                              </span>
                              <span v-else-if="!editAllergy">
                                <i
                                  class="far fa-edit cursor-pointer me-2"
                                  @click="editAllergy = allergy"
                                ></i>
                                <i
                                  class="
                                    fad
                                    fa-trash
                                    text-danger
                                    cursor-pointer
                                  "
                                  @click="deleteAllergy(allergy)"
                                ></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client: null,
      loading: true,
      allergies: [],
      showAdd: false,
      editAllergy: null,
      form: {
        type: "allergy",
        name: "",
        description: "",
        added_by: "practitioner",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchAllergies() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/allergies"
        )
        .then(({ data }) => {
          this.allergies = data;
          this.loading = false;
        });
    },
    createAllergy() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/allergies",
          this.form
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchAllergies();
          this.showAdd = false;
        });
    },
    updateAllergy() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/allergies/" +
            this.editAllergy.id,
          this.editAllergy
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchAllergies();
          this.editAllergy = null;
        });
    },
    deleteAllergy(allergy) {
      var confirmed = confirm(
        "Are you sure you wish to delete this record? This action is not reversible."
      );

      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/allergies/" +
              allergy.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchAllergies();
          });
      }
    },
    cancelEdit() {
      var confirmed = confirm(
        "Are you sure you wish to cancel? Any unsaved changes will be lost."
      );

      if (confirmed) {
        this.fetchAllergies();
        this.editAllergy = null;
      }
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchAllergies();
  },
  filters: {},
  computed: {},
  components: {},
};
</script>

<style>
</style>
